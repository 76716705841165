<template>
    <v-navigation-drawer
        v-model="openDrawer"
        fixed
        bottom
        right
        temporary
        width="35%"
    >
        <v-row justify="start" class="pa-5">
            <v-btn
                class="float-left"
                small
                icon
                fab
                color="black"
                @click="openDrawer = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-row>
        <IconLabel
            className="tw-flex tw-items-center"
            height="60"
            width="100%"
            text
            dense
            type="success"
        >
            <template v-slot:content>
                <span>Erfolgreich zum Warenkorb hinzugefügt</span>
            </template>
        </IconLabel>
        <ArticleBasketPreview
            v-for="(article, key) in articles"
            :key="key"
            :article="article"
        />
        <div class="tw-flex tw-justify-between tw-px-5 tw-pt-2">
            <div>Warenkorb</div>
            <div>{{ articles.length }} Artikel</div>
        </div>
        <div class="tw-flex tw-justify-between tw-px-5 tw-pt-2">
            <div>Verpackung & Versand</div>
            <div>Kostenlos</div>
        </div>
        <div class="tw-flex tw-justify-between tw-px-5 tw-pt-2">
            <div>
                <span class="font-weight-bold">Gesamsumme</span> (inkl. MwSt.)
            </div>
            <div class="font-weight-bold">EUR 159,00</div>
        </div>
        <v-row justify="space-between" class="px-5 tw-pt-2 tw-pb-5">
            <v-col cols="12" sm="12" md="6">
                <v-btn
                    block
                    outlined
                    @click="$router.push('kategorie')"
                    color="primary"
                    height="45"
                    tile
                >
                    Weiter einkaufen
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-btn
                    block
                    depressed
                    @click="$router.push('warenkorb')"
                    color="primary"
                    height="45"
                    tile
                >
                    Zum Warenkorb
                </v-btn>
            </v-col>
        </v-row>
        <v-divider />
        <v-row class="lg:tw-flex tw-hidden">
            <v-col cols="12" sm="12">
                <Title
                    class="tw-flex tw-items-center tw-justify-start tw-pl-5 tw-py-5 font-weight-medium"
                    :size="'17'"
                    :name="'Andere Kunden kaufen auch'"
                />
                <ArticleSlide
                    basket
                    :articles="slideArticles"
                    :width="130"
                    :pictureWidth="130"
                />
            </v-col>
        </v-row>
    </v-navigation-drawer>
</template>

<script>
import { EventBus } from '@/event-bus';
import Title from '@/components/shared/typography/Title';
import ArticleSlide from '@/pages/article/elements/ArticleSlide';
import ArticleBasketPreview from '@/components/content/items/article/ArticleBasketPreview';
import IconLabel from '@/components/shared/labels/icon-label/IconLabel';
import { articles } from '@/services/data-mock/articles';

export default {
    name: 'BasketPreview',
    components: {
        Title,
        IconLabel,
        ArticleBasketPreview,
        ArticleSlide,
    },
    data: () => ({
        openDrawer: null,
        slideArticles: [
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '1',
                price: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '3',
                price: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '4',
                price: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '3',
                price: '628,00',
                colors: ['black', 'blue'],
            },
        ],
        articles: [...[articles[0]]],
    }),
    mounted() {
        EventBus.$on('open-basket-drawer', () => {
            this.openDrawer = !this.openDrawer;
        });
    },
    beforeDestroy() {
        EventBus.$off('open-basket-drawer');
    },
};
</script>
<style scoped></style>
