import styled from 'vue-styled-components';

const titleProps = {
    size: String,
};

const Header = styled('div', titleProps)`
    font-size: ${props => props.size}px;
    font-weight: 500;
`;

export { Header };
