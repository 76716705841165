<template>
    <v-container>
        <div class="tw-flex tw-py-5">
            <div class="tw-flex tw-items-center">
                <img :src="article.picture" :alt="'article'" width="150" />
            </div>
            <div class="tw-flex tw-justify-between">
                <div class="tw-flex tw-flex-col tw-py-5 article">
                    <div class="article-title">{{ article.title }}</div>
                    <div class="tw-flex article-prices">
                        <div
                            class="tw-font-light tw-text-decoration-line-through tw-pr-2"
                        >
                            {{ article.currency }} {{ article.originalPrice }}
                        </div>
                        <div class="tw-font-bold">
                            {{ article.currency }}
                            {{ article.promotionalPrice }}
                        </div>
                    </div>
                    <div class="article-content">
                        <div class="tw-font-bold article-informations">
                            Größe {{ article.size }}, Farbe {{ article.color }}
                        </div>
                        <div class="tw-font-bold article-sub-informations tw-pt-2">
                            Lieferzeit: ca. 2-3 Werktage
                        </div>
                    </div>
                </div>
                <div class="delete-article tw-pt-3">
                    <v-btn large icon color="primary">
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
        <v-divider />
    </v-container>
</template>
<script>
export default {
    name: 'ArticleBasketPreview',
    data() {
        return {
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
        };
    },
    props: {
        article: Object,
    },
    updated: () => {},
    methods: {},
};
</script>
<style scoped>
.article-title {
    font-weight: 600;
    text-overflow: ellipsis;
}
.article-informations {
    font-size: 14px;
}
.article-sub-informations {
    font-size: 13px;
    color: grey;
}
</style>
