<template>
    <v-app
        :class="
            openMobileNavigation ?  'mobile-app-container'  : 'v-app-container'
        "
    >
        <div id="app" class="tw-bg-white app-container">
            <BasketPreview/>
            <Header/>
            <div v-if="!openMobileNavigation">
                <router-view/>
                <Footer/>
            </div>
        </div>
    </v-app>
</template>

<script>
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import BasketPreview from './pages/basket/BasketPreview';
import {EventBus} from '@/event-bus';

export default {
    name: 'App',
    components: {
        Header,
        Footer,
        BasketPreview,
    },
    data: () => ({
        openMobileNavigation: false,
    }),
    mounted() {
        EventBus.$on('open-mobile-nav', openNav => {
            this.openMobileNavigation =
                typeof openNav !== "undefined"
                    ? openNav
                    : !this.openMobileNavigation;
        });
    },
    beforeDestroy() {
        EventBus.$off('open-mobile-nav');
    },
};
</script>

<style>
.app-container {
    font-family: 'Rubik', sans-serif;
}

.v-app-container {

}

.mobile-app-container {
    overflow-y: hidden;
}

::-webkit-scrollbar {
    width: 9px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgb(223, 223, 223);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgb(204, 204, 204);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #b8b8b8;
}
</style>
