<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 18 20"
        :aria-labelledby="iconName"
        role="presentation"
        :style="{ fill: color }"
    >
        <title :id="iconName" lang="en">{{ iconName }} icon</title>
        <g id="package" transform="translate(-19.8 1)">
            <path
                id="Pfad_1"
                data-name="Pfad 1"
                d="M37.062,4.679a.52.52,0,0,0-.05-.222.485.485,0,0,0-.339-.268L28.141.05a.506.506,0,0,0-.44,0L19.085,4.226a.5.5,0,0,0-.285.436v10.5a.5.5,0,0,0,.285.452L27.7,19.791s0,0,.008,0a.149.149,0,0,1,.038.017s.008,0,.017,0l.038.013s.008,0,.013,0,.029,0,.042.008h.013c.017,0,.038,0,.054,0a.239.239,0,0,0,.054,0h.013a.149.149,0,0,0,.042-.008s.008,0,.013,0l.038-.013s.008,0,.017,0a.149.149,0,0,0,.038-.017s0,0,.008,0L36.786,15.6a.507.507,0,0,0,.285-.452V4.691C37.062,4.687,37.062,4.683,37.062,4.679ZM27.919,1.06l7.473,3.623L32.639,6.019,25.167,2.4Zm0,7.246L20.446,4.683l3.569-1.73,7.473,3.623ZM19.805,5.491l7.611,3.69v9.353l-7.611-3.69Zm8.616,13.043V9.181l3.573-1.734V9.894a.5.5,0,0,0,1.005,0V6.957l3.058-1.483v9.353Z"
                transform="translate(1 -1)"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'SvgBox',
    props: {
        iconName: {
            type: String,
            default: 'purple',
        },
        width: {
            type: [Number, String],
            default: 20,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
        color: {
            type: String,
            default: '#26348c',
        },
    },
};
</script>
