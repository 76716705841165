<template>
    <div class="tw-bg-koffer-gray-light tw-hidden lg:tw-block ">
        <div class="main-page-size tw-py-2 preheader-container">
            <div class="tw-h-5 tw-flex tw-justify-between">
                <div class="tw-space-x-6 tw-flex tw-items-center">
                    <router-link
                        v-for="(brand, key) in brands"
                        :key="key"
                        :to="brand.href"
                        class="tw-font-normal hover:tw-text-black tw-text-xs tw-font-sans tw-inline-flex tw-items-center tw-no-underline"
                        :class="[
                            brand.current
                                ? 'preheader-black'
                                : 'preheader-gray',
                        ]"
                    >
                        {{ brand.name }}
                    </router-link>
                </div>

                <div class="tw-flex tw-space-x-6 tw-justify-end">
                    <span
                        v-for="(information, key) in informations"
                        :key="key"
                        class="tw-font-sans tw-text-ms tw-inline-flex tw-items-center tw-no-underline tw-text-black tw-font-light"
                    >
                        <img
                            :src="information.icon"
                            v-if="information.icon"
                            class="tw-mr-2 tw-fill-current tw-text-black tw-w-3"
                        />
                        <span class="tw-text-xs xl:tw-text-base" v-html="information.name"></span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as IconFreeShipping from '@/assets/images/icon-gratis-versand.svg';
import * as IconReturnPolicy from '@/assets/images/icon-rueckgaberecht-schwarz.svg';
import * as IconTelephone from '@/assets/images/icon-telefon.svg';
export default {
    name: 'PreHeader',
    data: () => ({
        brands: [
            { name: 'koffer.de', href: '/', current: true },
            { name: 'Airliner', href: '/', current: false },
            { name: 'Kofferklinik', href: '/', current: false },
            { name: 'Filialen', href: '/', current: false },
        ],
        informations: [
            { icon: IconFreeShipping, name: 'Gratis Versand*' },
            { icon: IconReturnPolicy, name: '30 Tage Rückgaberecht' },
            {
                icon: IconTelephone,
                name:
                    'Bestellung, Service & Beratung&nbsp;&nbsp;<b>+49 6107 / 966 68 66</b>&nbsp;&nbsp;Mo.-Fr. 9-18 Uhr',
            },
        ],
    }),
};
</script>
<style scoped>

.preheader-black {
    color: black;
}
.preheader-gray {
    color: gray;
}
</style>
