<template>
    <div class="tw-bg-primary tw-flex sm:tw-hidden tw-text-white tw-px-14 tw-items-center">
        <div class="tw-text-2xl tw-font-medium">
            %
        </div>
        <div class="tw-text-base tw-font-medium tw-text-center tw-p-2 tw-uppercase">
            Exklusiv reduzierte preise für company name-kunden
        </div>
        <div class="tw-text-2xl tw-font-medium">
            %
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderDiscountBanner',
    data: () => ({}),
};
</script>
<style scoped></style>
