import colors from 'vuetify/lib/util/colors';

export default {
    primary: {
        base: '#26348c',
        darken1: '#012775',
    },
    secondary: '#ec6608',
    tertiary: colors.grey.base,
};
