<template>
    <div class="main-page-size">
        <div
            class="tw-flex tw-border-t tw-border-b tw-border-gray-200 
            dark:tw-border-gray-700 tw-py-8 tw-mx-auto  tw-border-0 
            lg:tw-border-b  tw-justify-evenly xl:tw-px-0  tw-my-10"
        >
            <div class="tw-hidden sm:tw-flex">
                <div
                    v-for="(i, index) in images"
                    :key="index"
                    class="tw-flex sm:tw-mx-6"
                >
                    <img
                        :src="i.image"
                        :alt="i.title"
                        class="tw-fill-current tw-text-black tw-mr-4 home-banner-icon"
                    />
                    <div>
                        <div class="tw-text-sm  tw-font-medium">
                            {{ i.title }}
                        </div>
                        <div class="tw-text-sm tw-font-light tw-d">
                            {{ i.description }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="sm:tw-hidden tw-block">
                <div
                    v-for="(i, index) in images"
                    :key="index"
                    class="tw-text-center tw-mb-4"
                >
                    <img
                        :src="i.image"
                        class="tw-mr-2 tw-fill-current tw-inline-block tw-align-middle tw-w-10 tw-mb-2"
                    />
                    <div>
                        <div class="tw-text-base tw-font-bold">
                            {{ i.title }}
                        </div>
                        <div class="tw-text-sm tw-font-light tw-d">
                            {{ i.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon1 from '@/assets/images/Icons/icon_rueckgaberecht_blau.svg';
import Icon2 from '@/assets/images/Icons/icon_whatsapp.svg';
import Icon3 from '@/assets/images/Icons/icon-laecheln.svg';

export default {
    name: 'ListDetails.vue',
    data: () => ({
        images: [
            {
                image: Icon1,
                title: '30 Tage Ruckgaberrecht',
                description: 'mit kosteniosern Ruckversand',
            },
            {
                image: Icon2,
                title: 'Telefonische Fachberatung',
                description: '+49 6107 / 966068066. Mo - Fr. 9 - 18 Uhr',
            },
            {
                image: Icon3,
                title: 'Lachel-Garantie',
                description: 'wir sand fur Sie da - garantiert personlich',
            },
        ],
    }),
};
</script>
<style scoped>
.home-banner-icon {
    width: 35px;
    height: 35px;
}
</style>
