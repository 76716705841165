<template>
    <Header :size="size" :class="className" v-text="name"></Header>
</template>
<script>
import { Header } from './style';
export default {
    name: 'Title',
    components: {
        Header,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        size: {
            type: String,
        },
        className: {
            type: String,
        },
    },
    computed: {},
};
</script>
