import Vue from 'vue';
import App from './App.vue';
import vClickOutside from 'v-click-outside';
import router from './router/';
import vuetify from "@/plugins/vuetify"; 
import '@mdi/font/css/materialdesignicons.css'
import "@/assets/styles/main.css";
import './assets/tailwind.css';


Vue.config.productionTip = false;

Vue.use(vClickOutside);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')