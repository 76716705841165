<template>
    <div class="main-page-size">
        <div
            class="tw-object-center tw-grid"
        >
            <div
                class="tw-text-white tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 sm:tw-gap-6 tw-gap-3"
            >
                <div
                    class="tw-pt-4 tw-pb-2 sm:tw-py-4 tw-px-4  tw-text-white tw-flex tw-flex-col tw-justify-end"
                    v-for="(categoryBanner, index) in categories"
                    :key="index"
                    :class="categoryBanner.bgcolor"
                >
                    <img
                        :src="categoryBanner.image"
                        class="tw-w-8 xs:tw-w-10"
                        :alt="categoryBanner.name"
                    />

                    <div
                        class="tw-inline-flex sm:tw-text-2xl tw-text-xs xs:tw-text-base sm:tw-mt-2 tw-mt-0"
                    >
                        {{ categoryBanner.name }}

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="tw-h-8 tw-w-8 tw-pl-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 7l5 5m0 0l-5 5m5-5H6"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BusinessIcon from '@/assets/images/icon-businessgepaeck.png';
import BackpackIcon from '@/assets/images/icon-rucksaecke.png';
import LuggageIcon from '@/assets/images/icon-reisegepaeck.png';
import SchoolIcon from '@/assets/images/icon-kindundschule.png';

export default {
    name: 'CategoryBanner',
    data: () => ({
        categories: [
            {
                name: 'Business',
                bgcolor: 'tw-bg-koffer-category-business',
                image: BusinessIcon,
            },
            {
                name: 'Rucksäcke',
                bgcolor: 'tw-bg-koffer-category-backpack',
                image: BackpackIcon,
            },
            {
                name: 'Reisegepäck',
                bgcolor: 'tw-bg-koffer-category-luggage',
                image: LuggageIcon,
            },
            {
                name: 'Kids & School',
                bgcolor: 'tw-bg-koffer-category-child',
                image: SchoolIcon,
            },
        ],
    }),
};
</script>
