<template>
    <div >
        <div class="tw-relative tw-inset-0 tw-z-0 tw-hidden md:tw-block">
            <div class="tw-z-1">
                <img
                    class="top-banner-image tw-mb-4 margin-blue-banner"
                    src="@/assets/images/banner/travelite.jpg"
                    alt="travelite"
                />


                <div class="tw-absolute right-arrow tw-hidden xl:tw-block" >
                    <img src="@/assets/images/Icons/icon-pfeil-slider_rechts_weiss.svg" alt="pfeil rechts" >
                </div>
                <div class="tw-absolute left-arrow tw-hidden xl:tw-block">
                    <img src="@/assets/images/Icons/icon-pfeil-slider_links_weiss.svg" alt="pfeil links" >
                </div>
            </div>

            <div
                class="tw-absolute  banner-slide-content"
            >
                <div
                    class="tw-py-10 tw-pl-10  tw-bg-koffer-category-luggage tw-bg-opacity-90"
                >
                    <p class="tw-text-xl tw-font-light tw-text-white ">
                        Der travelite NEXT
                    </p>
                    <p
                        class="tw-text-5xl 3xl:tw-text-3xl tw-text-white tw-font-medium tw-leading-tight"
                    >
                        Das nächste Kapitel für deine Reise.
                    </p>
                    <button
                        class="tw-px-4 tw-py-2 tw-font-light tw-font-medium tw-inline-flex tw-items-center tw-text-base tw-bg-white tw-text-primary"
                    >
                        Weiterlesen
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="tw-h-6 tw-w-6 tw-ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 7l5 5m0 0l-5 5m5-5H6"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

         <div class="tw-relative  md:tw-hidden">
            <div class="tw-overflow-hidden ">
                <img src="@/assets/images/banner/travelite.jpg" class="travelite-min-width">
            </div>
            <div class="mobile-arrow-right">
                <img src="@/assets/images/Icons/icon-pfeil-slider_rechts_weiss.svg" alt="pfeil rechts" class="tw-h-12 tw-mr-4">
            </div>

            <div class="mobile-arrow-left">
                <img src="@/assets/images/Icons/icon-pfeil-slider_links_weiss.svg" alt="pfeil links" class="tw-h-12 tw-ml-4">
            </div>
             <div class="banner-slide-content-mobile">
                 <div class="tw-bg-koffer-category-luggage tw-bg-opacity-90 tw-p-4 tw-mr-4 tw-mb-6">
                     <div class="tw-text-base tw-font-light tw-text-white">Der travelite NEXT</div>
                     <div class="tw-text-3xl xs:tw-text-4xl tw-font-bold tw-my-2 sm:tw-mt-1 tw-text-white">Das nächste Kapitel für deine Reise.</div>
                     <button class="tw-mt-1 tw-bg-white tw-text-base tw-text-blue-900 tw-font-normal tw-inline-flex tw-items-center tw-px-4 tw-py-2">
                         Weiterlesen

                         <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                         </svg>
                     </button>
                 </div>
             </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    data: () => ({
        currentImageIndex: 0,
        slides: [
            {
                mainPicture: '',
                box: {
                    preLine: '',
                    headLine: '',
                    button: '',
                    color: '',
                },
            },
        ],
    }),
    computed: {
        currentSlide() {
            return this.slides[this.currentImageIndex];
        },
        previousSlideIndex() {
            return (
                (this.currentImageIndex - 1 + this.slides.length) %
                this.slides.length
            );
        },
        previousSlide() {
            return this.slides[this.previousSlideIndex];
        },
        nextSlideIndex() {
            return (this.currentImageIndex + 1) % this.slides.length;
        },
        nextSlide() {
            return this.slides[this.nextSlideIndex];
        },
    },
};
</script>
<style scoped>
@media screen  and (min-width:640px) and (max-width:870px) {
    .margin-blue-banner{
        margin-bottom:11%;  
    }
}
@media screen  and (min-width:870px) and (max-width:1000px) {
    .margin-blue-banner{
        margin-bottom:5%; 
    }

}
@media screen  and (min-width:640px) and (max-width:700px) {
    .blue-banner{
        margin-bottom:11%;  
    }
}




@media screen   and (max-width:767px) {
.travelite-min-width{
    min-width: 640px;
    height: 260px;
}
 
.banner-slide-content-mobile {
    margin-right: auto;
    margin-top:-6%;
    position: inherit;
}
.mobile-arrow-right{  
    top: 22%;
    right: 0;
    position: absolute;
}
.mobile-arrow-left{  
    top: 22%;
    left: 0;
    position: absolute;

}
}


@media screen   and (min-width: 768px)and (max-width:1630px) {
.top-banner-image {
    width: calc(100% - 20px);
    right:0;
}
.banner-slide-content {
   width: calc(35% + 20px);
    top: 6%;
    left: 65%;
}
.right-arrow{
    top: 81%;
    right: calc(2% + 20px);
    height: 48px;
}
.left-arrow{
    top: 81%;
    right: calc(6% + 20px);
    height: 48px;
}
}
@media screen   and (min-width:1630px) {
.top-banner-image {
    width: calc(50% + 795px);
    right:0;
}
.banner-slide-content {
   width: calc(830px - 15%);
    top: 6%;
    left: 65%;
}
.right-arrow{
    top: 81%;
    right: calc(52% - 815px);
    height: 48px;
}
.left-arrow{
    top: 81%;
    right: calc(55% - 815px);
    height: 48px;
}
}
</style>
