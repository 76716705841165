import { render, staticRenderFns } from "./Personality.vue?vue&type=template&id=485405c2&scoped=true&"
import script from "./Personality.vue?vue&type=script&lang=js&"
export * from "./Personality.vue?vue&type=script&lang=js&"
import style0 from "./Personality.vue?vue&type=style&index=0&id=485405c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485405c2",
  null
  
)

export default component.exports