<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 43 51"
        :aria-labelledby="iconName"
        role="presentation"
        :style="{ fill: color }"
    >
        <title :id="iconName" lang="en">{{ iconName }} icon</title>
        <g id="phone-call" transform="translate(0.302 0.254)">
            <path
                id="Pfad_2"
                data-name="Pfad 2"
                d="M40.313,33.2a3.1,3.1,0,0,0-1.535-2.293l-9.952-5.73a3.123,3.123,0,0,0-3.76.5l-1.96,1.96a1.437,1.437,0,0,1-1.919.107,56.656,56.656,0,0,1-4.522-4.067A56.676,56.676,0,0,1,12.6,19.154a1.437,1.437,0,0,1,.107-1.919l1.96-1.96a3.122,3.122,0,0,0,.5-3.76L9.431,1.562A3.118,3.118,0,0,0,4.525.913L1.571,3.867C-.49,5.929-.523,9.7,1.478,14.486A46.9,46.9,0,0,0,11.59,28.75,46.893,46.893,0,0,0,25.855,38.862,16.634,16.634,0,0,0,32.1,40.339a5.957,5.957,0,0,0,4.372-1.571l2.954-2.954A3.1,3.1,0,0,0,40.313,33.2Zm-12.139-6.89,9.952,5.73a1.81,1.81,0,0,1,.553,2.644L25.453,27.137l.537-.537A1.814,1.814,0,0,1,28.174,26.311ZM6.967,1.323A1.8,1.8,0,0,1,8.3,2.214l5.73,9.952a1.814,1.814,0,0,1-.289,2.184l-.493.493L5.657,1.661a1.789,1.789,0,0,1,1.068-.355,1.845,1.845,0,0,1,.242.016ZM35.549,37.845c-1.638,1.638-4.987,1.569-9.19-.188a45.574,45.574,0,0,1-13.844-9.831A45.574,45.574,0,0,1,2.684,13.981C.926,9.779.858,6.429,2.5,4.791L4.688,2.6l7.6,13.2-.51.51a2.742,2.742,0,0,0-.2,3.663A57.981,57.981,0,0,0,15.74,24.6a58.011,58.011,0,0,0,4.627,4.162,2.742,2.742,0,0,0,3.663-.2l.465-.465,13.244,7.559Z"
                transform="translate(-0.001 0)"
                :fill="color"
                :stroke="color"
                stroke-width="0.3"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'SvgPhone',
    props: {
        iconName: {
            type: String,
        },
        width: {
            type: [Number, String],
            default: 20,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
        color: {
            type: String,
            default: '#26348c',
        },
    },
};
</script>
