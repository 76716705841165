<template>
    <v-expand-transition>
        <v-list
            v-if="openNav"
            class="d-lg-none tw-flex mobile-navigation-drawer tw-flex-col tw-justify-between"
        >
            <div>
                <v-divider />
                <v-list-group
                    active-class="mobile-nav-active"
                    v-for="(navigation, key) in navigations"
                    :class="navigation.key"
                    :key="key"
                    no-action
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="navigation.name"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        v-for="(child, key) in navigation.categories"
                        :key="key"
                    >
                        <v-list-item-content>
                            <router-link :to="child.route">
                                <v-list-item-title
                                    v-text="child.name"
                                ></v-list-item-title>
                            </router-link>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <div
                        class="tw-flex tw-flex-col tw-pb-5"
                        v-if="navigation.themes && navigation.themes.length > 0"
                    >
                        <span class="sublist-title tw-py-5 tw-px-7">
                            Themenwelten
                        </span>
                        <a
                            v-for="(theme, key) in navigation.themes"
                            :key="key"
                            class="tw-font-light text-md sublist-link"
                        >
                            {{ theme.name }}
                        </a>
                    </div>
                    <v-divider />
                    <div
                        class="tw-flex tw-flex-col"
                        v-if="navigation.brands && navigation.brands.length > 0"
                    >
                        <span class="sublist-title tw-py-5 tw-px-7">
                            Top Marken
                        </span>
                        <div
                            class="tw-grid tw-grid-cols-2 tw-gap-8 tw-justify-center tw-pb-5"
                        >
                            <a
                                href="#"
                                v-for="(brand, key) in navigation.brands"
                                :key="key"
                                class="tw-w-full tw-flex tw-justify-center tw-items-center"
                            >
                                <img
                                    class="brand-picture w-20"
                                    alt=""
                                    :src="brand.src"
                                />
                            </a>
                        </div>
                    </div>
                    <v-divider class="nav-bottom-separator" />
                </v-list-group>
            </div>
            <div class="nav-bottom-container">
                <div class="tw-flex tw-items-center tw-justify-center tw-py-6">
                    <SvgPhone :width="'60'" :height="'60'" :color="'black'" />
                    <div
                        class="tw-flex tw-justify-center tw-items-center tw-flex-col"
                    >
                        <div class="tw-font-light">
                            Bestellung, Service & Beratung
                        </div>
                        <div class="font-weight-medium tw-text-center">
                            +49 6107 / 966 68 66
                        </div>
                        <div class="tw-font-light tw-text-center">
                            Mo.-Fr. 9-18 Uhr
                        </div>
                    </div>
                </div>
                <div class="nav-bottom-section tw-py-6 xs:tw-pt-0">
                    <div class="tw-ml-6 tw-flex tw-justify-evenly">
                        <div
                            class="tw-flex  tw-py-2  xs:tw-items-center tw-text-center"
                        >
                            <SvgBox
                                class="mr-1"
                                :width="'20'"
                                :height="'20'"
                                :color="'black'"
                            />
                            <div class="tw-font-light ">
                                Gratis Versand*
                            </div>
                        </div>
                        <div
                            class="tw-flex tw-py-2 xs:tw-items-center tw-text-center"
                        >
                            <SvgReload
                                class="mr-1"
                                :width="'20'"
                                :height="'20'"
                                :color="'black'"
                            />
                            <div class="tw-font-light tw-p-0">
                                30 Tage Rückgaberecht
                            </div>
                        </div>
                    </div>
                    <div
                        class="tw-font-medium tw-flex tw-justify-around tw-px-1 tw-pt-1 tw-pb-2 mobile-nav-footer"
                    >
                        <span>Airliner</span>
                        <span>kofferklinik</span>
                        <span>Filialen</span>
                    </div>
                </div>
            </div>
        </v-list>
    </v-expand-transition>
</template>

<script>
import SvgBox from '../shared/icons/SvgBox';
import SvgPhone from '../shared/icons/SvgPhone';
import SvgReload from '../shared/icons/SvgReload';
import { EventBus } from '@/event-bus';

export default {
    name: 'MobileNavigation',
    components: {
        SvgBox,
        SvgPhone,
        SvgReload,
    },
    data() {
        return {
            isRouteChanged: false,
        };
    },
    props: {
        navigations: Array,
        openNav: Boolean,
    },
    watch: {
        $route(to, from) {
            EventBus.$emit('open-mobile-nav', false);
        },
    },
};
</script>

<style scoped>
.mobile-navigation-drawer {
    position: fixed;
    padding: 0;
    z-index: 20;
    height: 92vh;
    width: 100%;
    left: 0;
}
.v-list {
    overflow-y: auto;
}

.nav-bottom-section {
    background: rgb(228, 228, 228);
}

.mobile-nav-footer {
    color: #6f6f6f;
}

.v-list-group >>> .v-list-group__header .v-list-item__title {
    font-size: 20px;
    padding: 5px 8px;
}
.v-list-group >>> .v-icon {
    font-size: 35px;
    color: #4e4e4e;
}
.v-list-group >>> .v-list-group__items .v-list-item__title {
    font-size: 16px;
    color: rgb(85, 85, 85);
    cursor: pointer;
}

/*working classes*/
.v-list-group__items > .v-list-item {
    padding-left: 16px !important;
}

.v-list-group__items > .v-list-item .v-list-item__content {
    padding: 5px 8px !important;
}

.sublist-title {
    color: black;
}

.sublist-link {
    padding-left: 28px;
    color: rgb(85, 85, 85);
}

.luggage.v-list-group >>> .v-list-item.mobile-nav-active {
    background: #abcadf;
    color: white;
}

.luggage .v-divider.nav-bottom-separator {
    border: 7px solid;
    background: #abcadf;
    border-color: #abcadf;
}

.business.v-list-group >>> .v-list-item.mobile-nav-active {
    background: #505660;
    color: white;
}

.business .v-divider.nav-bottom-separator {
    border: 7px solid;
    background: #505660;
    border-color: #505660;
}

.backpack.v-list-group >>> .v-list-item.mobile-nav-active {
    background: #65a965;
    color: white;
}

.backpack .v-divider.nav-bottom-separator {
    border: 7px solid;
    background: #65a965;
    border-color: #65a965;
}

.bags.v-list-group >>> .v-list-item.mobile-nav-active {
    background: #0a9396;
    color: white;
}

.bags .v-divider.nav-bottom-separator {
    border: 7px solid;
    background: #0a9396;
    border-color: #0a9396;
}

.child.v-list-group >>> .v-list-item.mobile-nav-active {
    background: #facd00;
    color: white;
}

.child .v-divider.nav-bottom-separator {
    border: 7px solid;
    background: #facd00;
    border-color: #facd00;
}

.accessory.v-list-group >>> .v-list-item.mobile-nav-active {
    background: #80c7aa;
    color: white;
}

.accessory .v-divider.nav-bottom-separator {
    border: 7px solid;
    background: #80c7aa;
    border-color: #80c7aa;
}

.brand.v-list-group >>> .v-list-item.mobile-nav-active {
    background: #9b2226;
    color: white;
}

.brand .v-divider.nav-bottom-separator {
    border: 7px solid;
    background: #9b2226;
    border-color: #9b2226;
}

.sale.v-list-group >>> .v-list-item.mobile-nav-active {
    background: #e5e7eb;
    color: white;
}

.sale.v-list-group >>> .v-list-item__title {
    color: #ec6508;
}

.sale .v-divider.nav-bottom-separator {
    border: 7px solid;
    background: #e5e7eb;
    border-color: #e5e7eb;
}

.themes.v-list-group >>> .v-list-item.mobile-nav-active {
    background: #355070;
    color: white;
}

.themes .v-divider.nav-bottom-separator {
    border: 7px solid;
    background: #355070;
    border-color: #355070;
}

.v-list-group >>> .v-icon {
    transform: rotate(-90deg);
}
.v-list-group.v-list-group--active >>> .v-icon {
    transform: rotate(0deg) !important;
}
</style>
