<template>
    <div
        class="tw-border-t tw-border-b tw-border-gray-200 dark:tw-border-gray-700 tw-mx-100 tw-mx-auto tw-py-5 tw-border-0 lg:tw-border-b tw-px-6 xl:tw-px-0"
    >
        <div
            class="partner-list-container tw-gap-4 tw-flex tw-justify-between tw-flex-wrap tw-items-center"
        >
            <span v-for="(image, key) in images" :key="key">
                <img :src="image.src" :class="image.class" />
            </span>
        </div>
    </div>
</template>

<script>
import * as IconKaufaufrechnung from '@/assets/images/Icons/icon-kaufaufrechnung.svg';
import * as IconPaypal from '@/assets/images/Icons/icon-paypal.svg';
import * as IconSofortueberweisung from '@/assets/images/Icons/icon-sofortueberweisung.svg';
import * as IconMastercard from '@/assets/images/Icons/icon-mastercard.svg';
import * as IconVisa from '@/assets/images/Icons/icon-visa.svg';
import * as IconVorkasse from '@/assets/images/Icons/icon-vorkasse.svg';
import * as IconDhl from '@/assets/images/Icons/icon-dhl.svg';
import * as IconDpd from '@/assets/images/Icons/icon-dpd.svg';
export default {
    name: 'PartnersBanner',
    data: () => ({
        images: [
            {
                src: IconKaufaufrechnung,
                class: 'tw-mr-2 tw-fill-current tw-text-black tw-w-7',
            },
            {
                src: IconPaypal,
                class: 'tw-mr-2 tw-fill-current tw-text-black tw-w-7',
            },
            {
                src: IconSofortueberweisung,
                class: 'tw-mr-2 tw-fill-current tw-text-black tw-w-8 tw-h-12 tw-mt-2',
            },
            {
                src: IconMastercard,
                class: 'tw-mr-2 tw-fill-current tw-text-black tw-w-10 tw-mt-2',
            },
            {
                src: IconVisa,
                class:
                    'tw-mr-2 tw-fill-current tw-text-black tw-w-7',
            },
            {
                src: IconVorkasse,
                class: 'tw-mr-2 tw-fill-current tw-text-black tw-w-7',
            },
            {
                src: IconDhl,
                class: 'tw-mr-2 tw-fill-current tw-text-black tw-w-7',
            },
            {
                src: IconDpd,
                class: 'tw-mr-2 tw-fill-current tw-text-black tw-w-7',
            },
        ],
    }),
};
</script>

<style scoped>
.partner-list-container img {
    min-width: 120px;
}

@media (max-width: 639px) {
    .partner-list-container img {
        min-width: 90px;
    }
}
</style>
