import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../pages/home/Home.vue';

//Replace Router's push/replace functions to silence the rejection and make the promise resolve with the error
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => {
        if (VueRouter.isNavigationFailure(err)) {
            return err;
        }
        return Promise.reject(err);
    });
};

Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/warenkorb',
        name: 'basket',
        component: () => import('../pages/basket/Basket.vue'),
    },
    {
        path: '/kategorie',
        name: 'category',
        component: () => import('../pages/category/Category.vue'),
    },
    {
        path: '/artikel',
        name: 'artikel',
        component: () => import('../pages/article/Article.vue'),
    },
    {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('../pages/checkout/authentication/SignIn.vue'),
    },
    {
        path: '/adresse',
        name: 'address',
        component: () => import('../pages/checkout/Address.vue'),
    },
    {
        path: '/versand',
        name: 'shipping',
        component: () => import('../pages/checkout/Shipping.vue'),
    },
    {
        path: '/bezahlen',
        name: 'payment',
        component: () => import('../pages/checkout/Payment.vue'),
    },
    {
        path: '/bestaetigen',
        name: 'confirm',
        component: () => import('../pages/checkout/Confirm.vue'),
    },
    {
        path: '/danke',
        name: 'thankyou',
        component: () => import('../pages/checkout/ThankYou.vue'),
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: () => import('../pages/checkout/Checkout.vue'),
    },
];
const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
