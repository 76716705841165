<template>
    <div class="tw-border-b sm:tw-border-0 tw-border-gray-400">
        <div
            class="main-page-size lg:tw-text-base tw-text-base tw-pb-0 tw-z-50"
        >
            <div class="tw-relative">
                <div
                    class="tw-mx-auto tw-pt-5 tw-border-0 tw-flex tw-justify-between xl:tw-px-0"
                    :class="
                        $route.name === 'checkout'
                            ? ''
                            : 'tw-pb-5 lg:tw-border-b tw-border-koffer-gray-dark'
                    "
                >
                    <div class="tw-flex">
                        <div
                            @click="openMobileNavigation"
                            class="tw-flex lg:tw-hidden tw-mr-5 tw-cursor-pointer tw-items-center"
                        >
                            <svg
                                v-show="!openNavigation"
                                transition="fade-transition"
                                xmlns="http://www.w3.org/2000/svg"
                                class="tw-h-6 tw-w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M4 6h16M4 12h8m-8 6h16"
                                />
                            </svg>

                            <v-icon
                                v-show="openNavigation"
                                transition="fade-transition"
                                color="black"
                                size="32"
                                >mdi-close</v-icon
                            >
                        </div>
                        <div
                            class="tw-flex tw-items-center tw-cursor-pointer"
                            @click="$router.push('/')"
                        >
                            <img
                                src="@/assets/images/logo.svg"
                                class="sticky tw-top-0 tw-w-24 lg:tw-w-40"
                                alt="koffer.de"
                            />
                        </div>
                    </div>
                    <div
                        v-if="$route.name !== 'checkout'"
                        class="tw-relative tw-w-1/3 tw-hidden md:tw-flex tw-items-center"
                    >
                        <input
                            type="text"
                            name="search"
                            id="search"
                            class="tw-block search-input tw-w-full tw-pr-10 tw-py-2 tw-pl-4 tw-border-2 tw-border-gray-400 tw-rounded-3xl tw-font-light tw-text-sm focus:tw-outline-none"
                            placeholder="Suchbegriff eingeben"
                        />
                        <div
                            class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none"
                        >
                            <img
                                src="@/assets/images/icon-suche.svg"
                                class="tw-w-5"
                                alt="Suche"
                            />
                        </div>
                    </div>

                    <div
                        class="tw-flex tw-justify-end tw-space-x-3 sm:tw-space-x-5"
                    >
                        <div
                            v-if="$route.name === 'checkout'"
                            class="tw-flex tw-items-center tw-mx-2 tw-hidden lg:tw-flex"
                        >
                            <div
                                class="tw-font-light tw-text-gray-disabled tw-text-lg tw-pr-2"
                            >
                                Sicherer Bereich
                            </div>
                            <v-icon size="25" left color="grey">
                                mdi-lock-outline
                            </v-icon>
                            <v-divider vertical />
                        </div>
                        <router-link
                            to="/"
                            class="navigation-right-icon tw-flex tw-items-center"
                        >
                            <img
                                src="@/assets/images/icon-suche.svg"
                                alt="Suche"
                                class="md:tw-hidden"
                            />
                        </router-link>
                        <router-link
                            to="/"
                            class="navigation-right-icon tw-flex tw-items-center"
                        >
                            <img
                                src="@/assets/images/icon-customer.svg"
                                alt="Kunden"
                            />
                        </router-link>
                        <router-link
                            to="/"
                            class="navigation-right-icon tw-flex tw-items-center"
                        >
                            <img
                                src="@/assets/images/icon-wishlist.svg"
                                alt="Wunschliste"
                            />
                        </router-link>
                        <router-link
                            to="/warenkorb"
                            class="navigation-right-icon tw-flex tw-items-center"
                        >
                            <v-badge color="secondary" content="2" overlap left>
                                <img
                                    src="@/assets/images/icon-cart.svg"
                                    alt="Einkaufwagen"
                                />
                            </v-badge>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="tw-w-full"
            :class="$route.name === 'checkout' ? 'header-shadow' : ''"
        ></div>
        <div
            v-if="$route.name === 'checkout'"
            class="main-page-size tw-flex tw-items-center tw-justify-center tw-px-2 tw-py-1 tw-w-full tw-block lg:tw-hidden safe-area-mobile"
        >
            <div class="tw-font-light tw-text-lg tw-pr-2">
                Sicherer Bereich
            </div>
            <v-icon size="20" left>
                mdi-lock-outline
            </v-icon>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus';
export default {
    name: 'Items',
    data: () => ({
        openNavigation: false,
    }),
    mounted() {
        EventBus.$on('open-mobile-nav', openNav => {
            this.openNavigation =
                typeof openNav !== 'undefined' ? openNav : !this.openNavigation;
        });
    },
    beforeDestroy() {
        EventBus.$off('open-mobile-nav');
    },
    methods: {
        openMobileNavigation() {
            EventBus.$emit('open-mobile-nav');
        },
    },
};
</script>
<style scoped>
.search-input {
    border: 1px solid rgb(139, 139, 139);
}
.header-shadow {
    box-shadow: 0px 5px 8px -3px #efefef;
    height: 20px;
}
.navigation-right-icon {
    min-width: 25px;
    max-width: 25px;
    margin: 0 10px;
}
.navigation-right-icon img {
    min-width: 25px;
    max-width: 25px;
}
@media (max-width: 639px) {
    .navigation-right-icon {
        min-width: 18px;
        max-width: 18px;
    }
    .navigation-right-icon img {
        min-width: 18px;
        max-width: 18px;
        margin: 0 5px;
    }
    .v-badge >>> .v-badge__badge {
        inset: auto calc(100% - 17px) calc(100% - 10px) auto !important;
    }
}
.safe-area-mobile {
    color: rgb(134, 134, 134);
    background: rgb(238, 238, 238);
}
.safe-area-mobile .v-icon {
    color: rgb(134, 134, 134);
}
</style>
