<template>
    <div class="main-page-size-absolute sticky-navigation tw-hidden lg:tw-block tw-py-3">
        <div
            class="tw-justify-center tw-items-center tw-hidden lg:tw-flex"
            :class="
                windowTop > 140
                    ? 'md:tw-space-x-8 tw-space-x-16'
                    : 'tw-space-x-8 xl:tw-space-x-16'
            "
            v-click-outside="onClose"
        >
            <img
                @click="$router.push('/')"
                v-if="windowTop > 140"
                src="@/assets/images/logo.svg"
                class="tw-w-32 navigation-logo tw-cursor-pointer"
                alt="koffer.de"
            />
            <nav
                class="nav-item-link"
                aria-expanded="false"
                v-for="navigation in navigations"
                :key="navigation.key"
            >
                <div class="tw-flex tw-justify-center">
                    <button
                        type="button"
                        class="tw-uppercase tw-text-xs 2xl:tw-text-sm tw-font-medium focus:tw-outline-none hover:tw-underline"
                        :class="[
                            navigation.highlight
                                ? 'tw-text-koffer-orange-light'
                                : 'tw-text-black',
                            navigation.hover,
                        ]"
                        @click="onclick(navigation.key)"
                    >
                        {{ navigation.name }}
                    </button>
                    <v-expand-transition>
                        <div
                            class="tw-absolute tw-inset-x-0 tw-top-10 tw-shadow-lg tw-bg-white tw-border-b-20 menu-container
                            tw-space-x-12"
                            :class="navigation.border"
                            v-if="navigation.open && isMenuOpen"
                        >
                            <div
                                class="tw-text-base main-page-size tw-py-6 tw-grid tw-gap-y-6 tw-grid-cols-5"
                            >
                                <div class="tw-flex tw-flex-col tw-space-y-3">
                                    <span class="tw-mb-2 tw-text-sm">
                                        Kategorien
                                    </span>

                                    <router-link
                                        to="/kategorie"
                                        v-for="(category,
                                        key) in navigation.categories"
                                        :key="key"
                                        class="tw-font-light hover:tw-underline tw-text-sm tw-cursor-pointer expansion-link"
                                    >
                                        {{ category.name }}
                                    </router-link>
                                </div>

                                <div
                                    class="tw-flex tw-flex-col tw-flex tw-space-y-3"
                                >
                                    <span class="tw-mb-2 tw-text-sm">
                                        Themenwelten
                                    </span>

                                    <a
                                        href="#"
                                        v-for="(theme,
                                        key) in navigation.themes"
                                        :key="key"
                                        class="tw-font-light hover:tw-underline tw-text-sm expansion-link"
                                    >
                                        {{ theme.name }}
                                    </a>
                                </div>

                                <div class="tw-flex tw-flex-col tw-space-y-3">
                                    <span class="tw-mb-2 tw-text-sm">
                                        Top Marken
                                    </span>

                                    <div
                                        class="tw-grid tw-grid-cols-2 tw-gap-4"
                                    >
                                        <a
                                            href="#"
                                            v-for="(brand,
                                            key) in navigation.brands"
                                            :key="key"
                                            class="tw-font-light hover:tw-underline tw-w-16 expansion-link tw-flex tw-justify-center tw-items-center"
                                        >
                                            <img
                                                class="brand-picture"
                                                alt=""
                                                :src="brand.src"
                                            />
                                        </a>
                                    </div>
                                </div>

                                <div
                                    class="tw-flex tw-flex-col tw-justify-self-end tw-mr-2"
                                >
                                    <img
                                        class="banner-image"
                                        src="@/assets/images/banner-first.jpg"
                                        alt="banner-first"
                                    />
                                    <span class="tw-mb-2 tw-text-sm tw-pt-5">
                                        Sommer-Sale
                                    </span>
                                    <div
                                        class="tw-font-light tw-text-sm expansion-link"
                                    >
                                        Unsere Angebote
                                    </div>
                                </div>
                                <div
                                    class="tw-flex tw-flex-col tw-justify-self-end"
                                >
                                    <img
                                        class="banner-image"
                                        src="@/assets/images/banner-second.jpg"
                                        alt="banner-second"
                                    />
                                    <span class="tw-mb-2 tw-text-sm tw-pt-5">
                                        Sommer-Sale
                                    </span>
                                    <div
                                        class="tw-font-light tw-text-sm expansion-link"
                                    >
                                        Besonders hart im nehmen
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-expand-transition>
                </div>
            </nav>
            <img
                v-if="windowTop > 140"
                src="@/assets/images/Icons/icon-suche.svg"
                class="w-32 navigation-search-icon"
                alt="koffer.de"
            />
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
export default {
    name: 'Navigation',
    props: {
        navigations: Array,
    },
    data: () => ({
        isMenuOpen: true,
        windowTop: 0,
    }),
    mounted() {
        window.addEventListener('scroll', this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onClose() {
            this.navigations.forEach(item => (item.open = false));
        },
        onclick(key) {
            const navigationLastStatus = this.navigations.find(
                item => item.key === key
            ).open;
            if (!navigationLastStatus) {
                this.onClose();
                this.navigations.find(item => item.key === key).open = true;
            } else {
                this.isMenuOpen = true;
                this.navigations.find(item => item.key === key).open = false;
            }
        },
        onScroll(e) {
            this.windowTop = e.target.documentElement.scrollTop;
        },
    },
    watch: {
        $route(to, from) {
            this.navigations.forEach(item => (item.open = false));
        },
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
};
</script>

<style scoped>
.nav-item-link button {
    text-decoration-thickness: 2px;
}
.sticky-navigation {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 5;
    max-width: 100%;
    box-shadow: 0px 5px 8px -3px #efefef;
}
.navigation-logo {
    width: 80px;
}
.navigation-search-icon {
    width: 17px;
}
.expansion-link {
    color: rgb(56, 56, 56);
}
.brand-picture {
    width: 70px;
}
@media screen and (min-width: 1024px) and (max-width: 1324px) {
    .menu-container {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.banner-image {
    width: 246px;
    height: 290px;
}
</style>
