<template>
    <div>
        <Banner/>
        <CategoryBanner/>
        <ListPartners/>
        <div class="main-page-size">
            <Seperator/>
        </div>
        <div class="main-page-size">
            <ArticleSlide
                :articles="slideArticles"
            />
        </div>
        <ListDetails/>
        <Personality />
        <FirstHomeItem/>
        <div class="main-page-size">
            <ArticleSlide
                :articles="slideArticles"
                
            />
        </div>
        <SecondHomeItem/>
    </div>
</template>

<script>
import CategoryBanner from './elements/CategoryBanner';
import ListPartners from './elements/ListPartners';
import FirstHomeItem from './elements/FirstHomeItem';
import SecondHomeItem from './elements/SecondHomeItem';
import ListDetails from './elements/ListDetails';
import Banner from '@/components/content/banner/Banner';
import Seperator from '@/components/content/items/Seperator';
import ArticleSlide from '@/pages/article/elements/ArticleSlide';
import Personality from "@/pages/home/elements/Personality";

export default {
    name: 'Home',
    components: {
        Personality,
        Banner,
        CategoryBanner,
        ListPartners,
        ListDetails,
        FirstHomeItem,
        SecondHomeItem,
        ArticleSlide,
        Seperator,
    },
    data: () => ({
        slideArticles: [
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '1',
                price: '628,00',
                oldPrice: '628,00',
                isNew: true,
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '4',
                price: '628,00',
                oldPrice: '628,00',
                isNew: true,

                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '3',
                price: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '3',
                price: '628,00',
                colors: ['black', 'blue'],
            },
        ],
    }),
};
</script>
