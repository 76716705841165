<template>
    <div
        :class="basket ? 'basket-slide' : 'grid-slide'"
        class="product-slide tw-relative"
        show-arrows
    >
        <img
            src="@/assets/images/Icons/icon-pfeil-slider_links_schwarz.svg"
            class="arrow-previous"
        />
        <div class="tw-grid tw-grid-cols-4">
            <div
                v-for="(article, key) in articles"
                :key="key"
                class="tw-col-span-4 sm:tw-col-span-2 lg:tw-col-span-1 article tw-mr-3"
            >
                <ArticleElement
                    v-if="article.type === 'article'"
                    :article="article"
                    :width="width"
                    :height="height"
                    :pictureWidth="pictureWidth"
                    :pictureHeight="pictureHeight"
                    class="article-element"
                />
            </div>
        </div>
        <img
            src="@/assets/images/Icons/icon-pfeil-slider_rechts_schwarz.svg"
            class="arrow-next"
        />
    </div>
</template>
<script>
import ArticleElement from '@/components/content/items/article/ArticleElement';
export default {
    name: 'ArticleSlide',
    components: {
        ArticleElement,
    },
    props: {
        articles: Array,
        basket: Boolean,
        width: [String, Number],
        height: [String, Number],
        pictureWidth: [String, Number],
        pictureHeight: [String, Number],
    },
    data: () => ({
        model: null,
    }),
};
</script>
<style scoped>
.product-slide {
    display: flex;
}
.grid-slide .arrow-previous {
    position: absolute;
    z-index: 3;
    width: 19px;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    cursor: pointer;
}
.grid-slide .arrow-next {
    position: absolute;
    z-index: 3;
    width: 19px;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
    cursor: pointer;
}
.basket-slide {
    display: flex;
    justify-content: center;
}

.basket-slide .arrow-previous,
.basket-slide .arrow-next {
    width: 15px;
    margin: 0 10px;
}
.article-element:hover {
    box-shadow: none;
}


@media (max-width: 639px) {
    .article:not(:first-child) {
        display: none;
    }
}

@media (min-width: 639px) and (max-width: 1024px) {
    .article:nth-child(n + 3) {
        display: none;
    }
    .article {
        display: block;
    }
}

.article:last-child {
    margin-right: 0px !important;
}
</style>
