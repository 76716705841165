<template>
    <Label>
        <v-alert
            :class="className"
            :dense="dense"
            :min-height="height"
            :border="border"
            :max-width="maxWidth"
            :width="width"
            :text="text"
            :icon="icon"
            :color="color"
            :type="type"
            :outlined="outlined"
        >
            <slot name="content"></slot>
        </v-alert>
    </Label>
</template>
<script>
import { Label } from './style';
export default {
    name: 'IconLabel',
    components: {
        Label,
    },
    props: {
        color: {
            type: String,
        },
        height: [String, Number],
        width: [String, Number],
        maxWidth: [String, Number],
        icon: {
            type: String,
        },
        border: {
            validator: value => {
                return ['top', 'right', 'bottom', 'left'].indexOf(value) !== -1;
            },
        },
        dense: {
            type: Boolean,
        },
        outlined: {
            type: Boolean,
        },
        text: {
            type: Boolean,
        },
        type: {
            validator: value => {
                return (
                    ['success', 'info', 'warning', 'error'].indexOf(value) !==
                    -1
                );
            },
        },
        className: {
            type: String,
        },
    },
};
</script>
<style scoped>
.v-alert {
    width: fit-content;
}
.v-alert >>> .v-alert__wrapper .v-icon {
    margin: auto;
    margin-right: 16px;
}
</style>
