<template>
    <div class="main-page-size tw-object-center tw-pt-10">
        <div class="tw-grid">
            <div class="tw-flex tw-justify-center">
                <img
                    src="@/assets/images/Icons/icon-instagram.svg"
                    class="tw-h-12 tw-w-12"
                />
            </div>
            <div class="tw-flex tw-justify-center tw-text-3xl tw-font-medium tw-py-5">
                #koffer_de
            </div>
            <div
                class="tw-flex tw-max-w-4xl tw-max-w-md tw-mx-auto tw-text-center tw-text-base tw-font-light tw-items-center"
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation
            </div>

            <div class="lg:tw-mx-auto tw-mt-10">
                <div
                    class="tw-text-white tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-6"
                >
                    <div
                        class="tw-text-white"
                        v-for="(i, index) in images"
                        :key="index"
                    >
                        <img :src="i.image" />
                    </div>
                </div>
            </div>

            <Seperator class="tw-mb-4 tw-mt-4" />

            <div>
                <div class="tw-mx-auto tw-text-base tw-font-light">
                    <p class="tw-mb-2">
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                    <p class="tw-mb-2">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                    </p>
                    <p class="tw-mb-2">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                    </p>
                    <p class="tw-mb-2">
                        Venenatis lectus magna fringilla urna. Nibh nisl
                        condimentum id venenatis a condimentum vitae sapien
                        pellentesque. Donec massa sapien faucibus et molestie ac
                        feugiat sed. Augue interdum velit euismod in. Nullam ac
                        tortor vitae purus faucibus. Porttitor leo a diam
                        sollicitudin tempor id eu nisl. Egestas maecenas
                        pharetra convallis posuere morbi. Id ornare arcu odio ut
                        sem nulla pharetra diam. Cras ornare arcu dui
                    </p>
                    <p class="tw-mb-2">
                        Nunc scelerisque viverra mauris in aliquam sem
                        fringilla. Nec sagittis aliquam malesuada bibendum arcu.
                        Egestas congue quisque egestas diam in. Nam aliquam sem
                        et tortor consequat id porta. Faucibus interdum posuere
                        lorem ipsum dolor sit. Egestas pretium aenean pharetra
                        magna ac placerat vestibulum. Laoreet non curabitur
                        gravida arcu ac tortor dignissim. Porttitor lacus luctus
                        accumsan tortor. Aliquam malesuada bibendum arcu vitae
                        elementum curabitur vitae. Odio pellentesque diam
                        volutpat commodo sed egestas egestas fringilla
                        phasellus. Sit amet justo donec enim diam vulputate.
                    </p>
                    <p class="tw-mb-2">
                        Egestas purus viverra accumsan in nisl. Non quam lacus
                        suspendisse faucibus interdum posuere. Malesuada
                        pellentesque elit eget gravida cum. Euismod elementum
                        nisi quis eleifend quam adipiscing vitae proin sagittis.
                        Venenatis tellus in metus vulputate eu. Aliquet enim
                        tortor at auctor urna.
                    </p>
                    <p class="tw-mb-2">
                        Dignissim sodales ut eu sem integer vitae justo eget.
                        Sem viverra aliquet eget sit amet tellus cras. Massa
                        eget egestas purus viverra accumsan in nisl nisi
                        scelerisque. Pellentesque massa placerat duis ultricies
                        lacus sed turpis tincidunt. Nulla pharetra diam sit amet
                        nisl. Volutpat consequat mauris nunc congue nisi vitae
                        suscipit tellus mauris. Vestibulum lorem sed risus
                        ultricies tristique nulla aliquet enim tortor. Ut
                        tristique et egestas quis ipsum. Egestas quis ipsum
                        suspendisse ultrices gravida dictum fusce. Integer
                        malesuada nunc vel risus commodo viverra. Mus mauris
                        vitae ultricies leo. Nisl purus in mollis nunc sed id
                        semper risus in. Risus sed vulputate odio ut enim
                        blandit volutpat maecenas.
                    </p>
                    <p class="tw-mb-2">
                        Lectus nulla at volutpat diam ut venenatis tellus in
                        metus. Accumsan lacus vel facilisis volutpat est velit
                        egestas dui . Neque aliquam vestibulum morbi blandit
                        cursus. Vel fringilla est ullamcorper eget nulla. Etiam
                        dignissim diam quis enim lobortis scelerisque.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InstaBsp1 from '@/assets/images/Insta-Bsp-01.jpg';
import InstaBsp2 from '@/assets/images/Insta-Bsp-02.jpg';
import InstaBsp3 from '@/assets/images/Insta-Bsp-03.jpg';
import InstaBsp4 from '@/assets/images/Insta-Bsp-04.jpg';
import Seperator from '@/components/content/items/Seperator';

export default {
    name: 'SecondHomeItem',
    components: { Seperator },
    data: () => ({
        images: [
            { image: InstaBsp3 },
            { image: InstaBsp1 },
            { image: InstaBsp2 },
            { image: InstaBsp4 },
        ],
    }),
};
</script>
