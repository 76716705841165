<template>
  <hr class="tw-mb-8" />
</template>

<script>

export default {
  name: 'Seperator',
}
</script>

<style scoped>

</style>