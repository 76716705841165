<template>
    <header class="tw-shadow-md header-container">
        <PreHeader/>
        <Items :navigations="this.navigations"/>
        <MobileNavigation
            :openNav="openMobileNavigation"
            :navigations="this.navigations"
        />
        <HeaderDiscountBanner v-if="$route.name !== '/'"/>
        <Navigation
            v-if="$route.name !== 'checkout'"
            :navigations="this.navigations"
        />
    </header>
</template>

<script>
import PreHeader from './PreHeader';
import Items from './Items';
import MobileNavigation from './MobileNavigation';
import Navigation from './Navigation';
import HeaderDiscountBanner from './HeaderDiscountBanner';
import PartnerOne from '../../assets/images/partners/p-1.png';
import PartnerTwo from '../../assets/images/partners/p-2.png';
import PartnerThree from '../../assets/images/partners/p-3.png';
import PartnerFour from '../../assets/images/partners/p-4.png';
import PartnerFive from '../../assets/images/partners/p-5.png';
import PartnerSix from '../../assets/images/partners/p-6.png';
import PartnerSeven from '../../assets/images/partners/p-7.png';
import PartnerEight from '../../assets/images/partners/p-8.png';
import {EventBus} from '@/event-bus';

export default {
    name: 'Header',
    components: {
        Navigation,
        PreHeader,
        MobileNavigation,
        HeaderDiscountBanner,
        Items,
    },
    data: () => ({
        openMobileNavigation: false,
        view: {
            atTopOfPage: true,
        },
        navigations: [
            {
                key: 'luggage',
                name: 'Reisegepäck',
                highlight: false,
                open: false,
                hover: 'hover:tw-text-koffer-category-luggage',
                border: 'tw-border-koffer-category-luggage',
                categories: [
                    {name: 'Trolleys', route: '/kategorie'},
                    {name: 'Handgepäck', route: '/kategorie'},
                    {name: 'Große Koffer', route: '/kategorie'},
                    {name: 'Koffer Sets', route: '/kategorie'},
                    {name: 'Reisetaschen', route: '/kategorie'},
                    {name: 'Kleidersäcke', route: '/kategorie'},
                    {name: 'Beautycases', route: '/kategorie'},
                    {name: 'Sondergepäck', route: '/kategorie'},
                    {name: 'Auto Reisetaschen Sets', route: '/kategorie'},
                ],
                themes: [{name: 'Outdoor'}, {name: 'Aluminiumkoffer'}],
                brands: [
                    {src: PartnerOne},
                    {src: PartnerTwo},
                    {src: PartnerThree},
                    {src: PartnerFour},
                    {src: PartnerFive},
                    {src: PartnerSix},
                    {src: PartnerSeven},
                    {src: PartnerEight},
                ],
            },
            {
                key: 'business',
                name: 'Business Gepäck',
                highlight: false,
                open: false,
                hover: 'hover:tw-text-koffer-category-business',
                border: 'tw-border-koffer-category-business',
                themes: [{name: 'Outdoor'}, {name: 'Aluminiumkoffer'}],
            },
            {
                key: 'backpack',
                name: 'Rucksäcke',
                highlight: false,
                open: false,
                hover: 'hover:tw-text-koffer-category-backpack',
                border: 'tw-border-koffer-category-backpack',
                themes: [{name: 'Outdoor'}, {name: 'Aluminiumkoffer'}],
            },
            {
                key: 'bags',
                name: 'Taschen',
                highlight: false,
                open: false,
                hover: 'hover:tw-text-koffer-category-bags',
                border: 'tw-border-koffer-category-bags',
                themes: [{name: 'Outdoor'}, {name: 'Aluminiumkoffer'}],
            },
            {
                key: 'child',
                name: 'Kind & Schule',
                highlight: false,
                open: false,
                hover: 'hover:tw-text-koffer-category-child',
                border: 'tw-border-koffer-category-child',
                themes: [{name: 'Outdoor'}, {name: 'Aluminiumkoffer'}],
            },
            {
                key: 'accessory',
                name: 'Accessoires',
                highlight: false,
                open: false,
                hover: 'hover:tw-text-koffer-category-accessory',
                border: 'tw-border-koffer-category-accessory',
                themes: [{name: 'Outdoor'}, {name: 'Aluminiumkoffer'}],
            },
            {
                key: 'brand',
                name: 'Marken',
                highlight: false,
                open: false,
                hover: 'hover:tw-text-koffer-category-brand',
                border: 'tw-border-koffer-category-brand',
                themes: [{name: 'Outdoor'}, {name: 'Aluminiumkoffer'}],
            },
            {
                key: 'sale',
                name: 'Sale %',
                highlight: true,
                open: false,
                hover: 'hover:tw-text-koffer-orange-dark',
                border: 'tw-border-koffer-category-light',
                themes: [{name: 'Outdoor'}, {name: 'Aluminiumkoffer'}],
            },
            {
                key: 'themes',
                name: 'Themenwelten',
                highlight: false,
                open: false,
                hover: 'hover:tw-text-koffer-category-themes',
                border: 'tw-border-koffer-category-themes',
                themes: [{name: 'Outdoor'}, {name: 'Aluminiumkoffer'}],
            },
        ],
    }),
    beforeMount() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (window.pageYOffset > 50) {
                if (this.view.atTopOfPage) this.view.atTopOfPage = false;
            } else {
                if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
            }
        },
    },
    mounted() {
        EventBus.$on('open-mobile-nav', openNav => {
            this.openMobileNavigation =
                typeof openNav !== 'undefined'
                    ? openNav
                    : !this.openMobileNavigation;
        });
    },
    beforeDestroy() {
        EventBus.$off('open-mobile-nav');
    },
};
</script>

<style scoped>
div.scrolled {
    @apply tw-shadow-2xl;
    border-bottom: 0px;
    display: flex;
}

.header-container {
    display: unset;
}
</style>
