<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 40 40"
        :aria-labelledby="iconName"
        role="presentation"
        :style="{ fill: color }"
    >
        <title :id="iconName" lang="en">{{ iconName }} icon</title>
        <g
            id="Gruppe_1046"
            data-name="Gruppe 1046"
            transform="translate(-423 -1940.25)"
        >
            <g
                id="Gruppe_2"
                data-name="Gruppe 2"
                transform="translate(423 1940.25)"
            >
                <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(0)">
                    <path
                        id="Pfad_3"
                        data-name="Pfad 3"
                        d="M45.178,0a1.222,1.222,0,0,0-1.222,1.222V5.687A15.858,15.858,0,0,0,17.067,17.111a1.222,1.222,0,1,0,2.444,0A13.445,13.445,0,0,1,42.873,8.036L37.458,9.841a1.224,1.224,0,1,0,.772,2.322l7.333-2.444A1.222,1.222,0,0,0,46.4,8.556V1.222A1.222,1.222,0,0,0,45.178,0Z"
                        transform="translate(-17.067)"
                        :fill="color"
                    />
                </g>
            </g>
            <g
                id="Gruppe_4"
                data-name="Gruppe 4"
                transform="translate(425.444 1956.139)"
            >
                <g id="Gruppe_3" data-name="Gruppe 3" transform="translate(0)">
                    <path
                        id="Pfad_4"
                        data-name="Pfad 4"
                        d="M79.309,221.867a1.222,1.222,0,0,0-1.222,1.222,13.445,13.445,0,0,1-23.362,9.075l5.414-1.805a1.224,1.224,0,0,0-.772-2.322l-7.333,2.444a1.222,1.222,0,0,0-.836,1.164v7.333a1.222,1.222,0,0,0,2.445,0v-4.465a15.858,15.858,0,0,0,26.889-11.424A1.222,1.222,0,0,0,79.309,221.867Z"
                        transform="translate(-51.198 -221.867)"
                        :fill="color"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'SvgReload',
    props: {
        iconName: {
            type: String,
        },
        width: {
            type: [Number, String],
            default: 20,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
        color: {
            type: String,
            default: '#26348c',
        },
    },
};
</script>
