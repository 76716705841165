<template>

    <div class="main-page-size">
        <div
            class="tw-object-center sm:tw-mx-6 lg:tw-mx-auto tw-grid tw-mb-4 "
        >
            <img
                src="@/assets/images/home-img-1.jpg"
                class="tw-full-width"
                alt="home"
            />
            <br/>
            <div class="tw-flex tw-justify-center tw-py-5">
                <img
                    src="@/assets/images/Icons/icon-laecheln.svg"
                    alt="lächeln"
                    width="55"
                />
            </div>
            <br/>
            <div
                class="tw-text-center tw-font-medium tw-text-xl sm:tw-text-2xl"
            >
                Lederwaren mit Personlichkeit.
            </div>
            <div
                class="tw-text-center tw-font-medium tw-text-xl sm:tw-text-2xl"
            >
                Was koffer.de so besonders macht.
            </div>
            <br/>
            <div
                class="tw-flex tw-max-w-4xl tw-max-w-md tw-mx-auto tw-text-center tw-text-base tw-font-light d tw-items-center"
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Cras sit amet tincidunt augue. Interdum et malesuada fames
                ac ante ipsum primis in faucibus. Integer vitae justo ut sem
                fringilla congue at at urna. Etiam tempus dolor eu nibh
                sagittis elementum.
            </div>
            <br/>
            <div class="tw-flex tw-justify-center tw-pb-5">
                <button
                    class="tw-bg-secondary tw-text-white tw-py-2 tw-flex more-button tw-text-base"
                >
                    Mehr lesen
                    <img
                        src="@/assets/images/Icons/icon-pfeil-link_weiss.svg"
                        alt="mehr lesen"
                        class="tw-w-5 tw-ml-3"
                    />
                </button>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Personality',
};
</script>

<style scoped>
.more-button {
    padding: 10px 47px;
}

</style>
