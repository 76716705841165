<template>
    <div class="main-page-size">
        <div
            class="tw-justify-between tw-mx-auto tw-py-5 sm:tw-flex tw-hidden tw-flex-wrap tw-gap-3"
        >
            <img
                v-for="(partner, key) in partners"
                :key="key"
                :src="require(`@/assets/images/${partner.img}`)"
                class="tw-fill-current tw-w-20"
                alt="partner"
                :class="partner.class"
            />
        </div>
        <div
            class="tw-flex sm:tw-hidden tw-justify-between tw-py-5 "
        >
            <img
                v-for="(partner, key) in mobilePartners"
                :key="key"
                :src="require(`@/assets/images/${partner.img}`)"
                class="tw-fill-current tw-text-black tw-w-16 xs:tw-w-20"
                alt="partner"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListPartners',
    data: () => ({
        partners: [
            { img: 'Mega-Dropdown/Marke-Dropdown_Victorinox.jpg' },
            { img: 'Mega-Dropdown/Marke-Dropdown_deuter.jpg' },
            { img: 'Mega-Dropdown/Marke-Dropdown_Hardware.jpg' },
            { img: 'Mega-Dropdown/Marke-Dropdown_Stratic.jpg' },
            { img: 'Mega-Dropdown/Marke-Dropdown_Samsonite.jpg' },
            { img: 'Marken-sw/-MICO(34).png' },
            { img: 'Mega-Dropdown/Marke-Dropdown_Picard.jpg' },
            { img: 'Mega-Dropdown/Marke-Dropdown_Piquadro.jpg' },
            { img: 'Marken-sw/reisenthel.png', class: 'my-4' },
            { img: 'Mega-Dropdown/Marke-Dropdown_TITAN.jpg' },
            { img: 'Marken-sw/travelite(1).png', class: 'my-4' },
            { img: 'Marken-sw/tumi-logo-red(1).png', class: 'my-4' },
        ],

        mobilePartners: [
            { img: 'Mega-Dropdown/Marke-Dropdown_Victorinox.jpg' },
            { img: 'Mega-Dropdown/Marke-Dropdown_deuter.jpg' },
            { img: 'Mega-Dropdown/Marke-Dropdown_Hardware.jpg' },
            { img: 'Mega-Dropdown/Marke-Dropdown_Stratic.jpg' },
        ],
    }),
};
</script>
