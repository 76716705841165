import ArticleOne from '../../assets/images/articles/Produktbeispiel_1.jpg';
import ArticleTwo from '../../assets/images/articles/Produktbeispiel_2.jpg';

const articles = [
    {
        picture: ArticleOne,
        title:
            'Titan Xenon Trolley 4 Rollen Text kann hier gut auch zweizeiling werden',
        number: 1,
        brand: 'Titan',
        size: 'M',
        color: 'Bluestone',
        id: '33889909',
        currency: 'EUR',
        originalPrice: '199,00',
        promotionalPrice: '159,00',
    },
    {
        picture: ArticleTwo,
        title:
            'Travelite Kick Off Freizeittasche Text Kann hier gut auch zweizeiling werden',
        number: 1,
        brand: 'Travelite',
        size: 'M',
        color: 'Petrol',
        id: '998800088',
        currency: 'EUR',
        originalPrice: '50,00',
        promotionalPrice: '31,00',
    },
];

export { articles };
