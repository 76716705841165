<template>
    <div class="main-page-size tw-pb-0">
        <div class="tw-pt-12">
            <footer id="footer" class="footer-container">
                <PartnersBanner />

                <div
                    class="tw-gap-4 tw-border-gray-200 dark:tw-border-gray-700 tw-py-16 tw-mx-100 tw-mx-auto tw-border-0 xl:tw-px-0 tw-grid tw-grid-cols-4"
                >
                    <div
                        class="xl:tw-border-r dark:tw-border-gray-700 tw-pr-4 tw-col-span-4 md:tw-col-span-2 lg:tw-col-span-1  "
                    >
                        <h3 class="tw-text-base tw-mb-6">Kundenservice</h3>
                        <ul class="footer-list">
                            <li
                                v-for="(item, key) in partOne"
                                :key="key"
                                :href="item.href"
                                class="tw-text-sm tw-d tw-items-center"
                            >
                                <a class="tw-font-light">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>

                    <div
                        class="xl:tw-border-r dark:tw-border-gray-700 sm:tw-pr-0 lg:tw-px-4 tw-px-0 xl:tw-border-b-0 tw-col-span-4 md:tw-col-span-2 lg:tw-col-span-1"
                    >
                        <h3 class="tw-text-base tw-mb-6">Koffer.de</h3>
                        <ul class="footer-list tw-mb-8">
                            <li
                                v-for="(item, key) in partTwo"
                                :key="key"
                                :href="item.href"
                                class="tw-text-sm tw-font-light tw-d tw-items-center tw-no-underline hover:tw-text-black"
                            >
                                <a class="tw-font-light">{{ item.name }}</a>
                            </li>
                        </ul>
                        <div
                            class="tw-border-t-2 md:tw-border-t-0 tw-border-b-2 md:tw-border-b-0 tw-py-4 md:tw-py-0 "
                        >
                            <h3
                                class="tw-text-xl tw-mb-4 tw-text-center md:tw-text-left "
                            >
                                Folgt uns
                            </h3>

                            <div
                                class="tw-flex tw-justify-center tw-gap-4 md:tw-justify-start md:tw-gap-1"
                            >
                                <img
                                    src="@/assets/images/Icons/icon-twitter.svg"
                                    class="tw-mr-2 tw-w-10 md:tw-w-6"
                                />
                                <img
                                    src="@/assets/images/Icons/icon-fb.svg"
                                    class="tw-mr-2 tw-w-10 md:tw-w-6"
                                />
                                <img
                                    src="@/assets/images/Icons/icon-pinterest.svg"
                                    class="tw-mr-2 tw-w-10 md:tw-w-6"
                                />
                                <img
                                    src="@/assets/images/Icons/icon-instagram.svg"
                                    class="tw-mr-2 tw-w-10 md:tw-w-6"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        class="tw-border-b xl:tw-border-b-0 lg:tw-px-4 tw-px-0 sm:px-0 pb-4 xl:tw-border-r dark:tw-border-gray-700 xl:tw-border-b-0 tw-col-span-4 lg:tw-col-span-1 md:tw-col-span-4 sm:tw-col-span-4"
                    >
                        <h3 class="tw-text-base tw-mb-6">Unsere Filiale</h3>

                        <p class="tw-text-sm tw-font-light tw-mb-4">
                            Luggage Gallery by Koffer.de<br />
                            Amelia-Mary-Earhart-Str. 8<br />
                            60549 Frankfurt am Main<br />
                            Gateway Gardens<br />
                            <a
                                class="tw-text-yellow-600 tw-underline footer-link"
                                >Route planen</a
                            >
                        </p>
                        <p class="tw-text-sm tw-font-light tw-mb-4">
                            Telefon +49 69 69861876 (Durchwahl zur Filiale)<br />
                            Koffer.de-Hotline: +49 6107 / 966 68 66<br />
                        </p>
                        <p class="tw-text-sm tw-font-light tw-mb-4">
                            Offnungszeiten:<br />
                            Mo-Fr 9-18 Uhr<br />
                            Sa 9-14 Uhr<br />
                        </p>
                    </div>

                    <div
                        class="tw-border-b xl:tw-border-b-0 lg:tw-px-4 tw-px-0 sm:px-0 pb-4 xl:tw-border-r dark:tw-border-gray-700 xl:tw-border-b-0 tw-col-span-4 lg:tw-col-span-1 md:tw-col-span-4 sm:tw-col-span-4"
                    >
                        <img
                            src="@/assets/images/Icons/icon-zertifizierter-shop.jpg"
                            class="tw-w-48 tw-h-24 "
                        />
                        <br />
                        <p class="tw-text-sm tw-font-light">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import PartnersBanner from './elements/PartnersBanner';

export default {
    name: 'Footer',
    components: {
        PartnersBanner,
    },
    data: () => ({
        partOne: [
            { name: 'Hilfe', href: '#', title: false },
            { name: 'Koffer Reparatur', href: '#', title: false },
            { name: 'widerruf & Reklamation', href: '#', title: false },
            { name: 'Versand & Zahlung', href: '#', title: false },
            { name: 'Geschenk-Gutscheine ', href: '#', title: false },
        ],
        partTwo: [
            { name: 'Affiliate Partnerprogramm', href: '#', title: false },
            { name: 'Datenschutz', href: '#', title: false },
            { name: 'AGB', href: '#', title: false },
            { name: 'Impressum', href: '#', title: false },
        ],
    }),
};
</script>

<style scoped>
.footer-container a {
    color: black;
}

ul.footer-list {
    margin-left: 0px;
    padding-left: 0px;
}

ul.footer-list li {
    margin-bottom: 16px;
}

.footer-link {
    color: #ec6608 !important;
}
</style>
