<template>
    <div>
        <div class="tw-relative">
            <div class="img-container">
            <img
                src="@/assets/images/home-img-2.jpg"
                alt="banner"
                class="banner2-image"
            />
            </div>
            <div
                class="banner2-slide-content"
            >
                <div
                    class="tw-py-4 tw-px-8 sm:tw-p-10 tw-bg-koffer-category-child tw-bg-opacity-90"
                >
                    <div
                        class="xl:tw-text-xl tw-text-lg tw-font-light tw-text-white"
                    >
                        Beständig & sicher
                    </div>
                    <div
                        class="3xl:tw-text-3xl 2xl:tw-text-5xl xl:tw-text-4xl lg:tw-text-2xl md:tw-text-base xl:tw-mt-8 lg:tw-mt-1 md:tw-mt-0 tw-text-4xl tw-font-medium sm:tw-mt-4 tw-text-white tw-py-1 sm:tw-pt-4"
                    >
                        Schulranzen auf und los geht's!
                    </div>
                    <div
                        class="tw-hidden lg:tw-block  xl:tw-text-lg tw-text-base xl:tw-mt-8 lg:tw-mt-1 tw-mt-0 tw-font-light tw-text-white  sm:tw-pt-4"
                    >
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do eiusmod tempor sed do eiusmod tempor sed do eiusmod tempor sed do eiusmod tempor. 
                    </div>

                    <button
                        class="xl:tw-mt-4 xl:tw-mt-8 lg:tw-mt-0 lg:tw-mt-0 tw-bg-white tw-text-base tw-font-medium tw-inline-flex tw-items-center tw-px-4 tw-py-2"
                    >
                        Weiterlesen

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="tw-h-6 tw-w-6 tw-ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 7l5 5m0 0l-5 5m5-5H6"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="clear" ></div>
        </div>

        <div class="clear">
            <a href="#"> </a>
        </div>
        <div class="tw-text-2xl sm:tw-text-3xl tw-mx-6 tw-text-center tw-font-medium tw-my-8">
            NEU - gerade eingetroffen
        </div>
    </div>
</template>

<script>
export default {
    name: 'FirstHomeItem',
};
</script>

<style scoped>
@media screen and (max-width: 640px)  {
.img-container{
    overflow: hidden;
}
.banner2-image {
   min-width:120%;
   margin-left: -10%;
}
}
@media screen and (max-width: 1100px)  {
.banner2-slide-content {
   top: -60px !important;
   right: auto !important;
   margin-right: 8%;
   position:relative;
}
}
@media screen and (min-width: 1100px)  {
    .banner2-slide-content {
        top: 60px !important;
        right: auto !important;
        position: absolute;
           width: 30%;
    }
    
}
@media screen and (max-width: 1630px)  and (min-width: 1100px)  {
    .banner2-image {
      margin-left: 20px;
   }
}
@media screen and  (min-width: 1630px)  {
.banner2-slide-content {
        top: 60px !important;
        left: calc(47% - 815px) !important;
        position: absolute;
           width: 30%;
    }
.banner2-image {
      margin-left: calc(50% - 815px);
   }
}
.clear {
    clear: right;
}

</style>
