<template>
    <div class="article-card tw-p-2">
        <div class="article-card--isNew" v-if="article.isNew">NEU</div>
        <div
            class="article-card--discount"
            :style="{ fontSize: width / 14 + 'px' }"
            v-if="article.discount !== undefined"
            :class="article.isNew ? 'flag-isnew' : ''"
        >
            {{ article.discount }}
        </div>

        <div v-if="!article.noFav" class="article-card--whistlist">
            <img
                :style="{ width: width / 5 + 'px' }"
                src="@/assets/images/Icons/icon-wishlist.svg"
            />
        </div>
        <router-link to="/artikel"
            ><img
                class="article-card--image"
                width="100%"
                height="100%"
                :src="
                    require(`@/assets/images/articles/Produktbeispiel_${article.img}.jpg`)
                "
        /></router-link>
        <label class="tw-text-xs tw-font-light tw-font-sans">{{
            article.label
        }}</label>
        <p
            class="tw-text-sm tw-font-light tw-mb-2"
            :style="{

                textOverflow: 'ellipsis',
                overflow: 'hidden',
            }"
        >
            {{ article.shortDesc }}
        </p>
        <div
            class="sm:tw-flex"

        >
            <div
                v-if="article.oldPrice !== undefined"
                class="tw-font-light tw-text-base tw-line-through tw-mr-2"
                >
                EUR {{ article.oldPrice }}
            </div>
            <div
                 class="tw-font-bold tw-text-base"
            >
                EUR
                {{ article.price }}
             </div>
        </div>

        <div v-if="article.colors" class="tw-mt-2">
            <span
                v-for="(color, key) in article.colors"
                class="article-dot"
                :class="`article-color-${color}`"
                :key="key"
            >
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticleElement',
    props: {
        article: Object,
        width: [String, Number],
        height: [String, Number],
        pictureWidth: [String, Number],
        pictureHeight: [String, Number],
    },
};
</script>

<style scoped>

.article-card {
    position: relative;
}

.article-card:hover {
    cursor: pointer;
    -webkit-box-shadow: 2px 5px 8px 2px rgb(203 203 203 / 70%);
    box-shadow: 2px 5px 8px 2px rgb(203 203 203 / 70%);
    border-radius: 5px;
}

.article-card--isNew {
    position: absolute;
    background-color: #ec6608;
    color: white;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    top: 16px;
    left: 10px;
}

.article-card--discount {
    position: absolute;
    background-color: #ec6608;
    color: white;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    top: 16px;
    left: 10px;
}

.article-card--discount.flag-isnew {
    top: 56px;
}

.article-card--whistlist {
    position: absolute;
    top: 17px;
    right: 10px;
    width: 30px;
}

.article-dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 4px;
}

.article-color-grey {
    background-color: #8a8a8a;
}

.article-color-red {
    background-color: #9b2227;
}

.article-color-black {
    background-color: #000000;
}

.article-color-blue {
    background-color: #26348c;
}
</style>
